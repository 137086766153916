<template>
  <div class="popup-comment-on-moderation">
    <div class="popup-comment-on-moderation__wrapper">
      <p class="popup-comment-on-moderation__title">
        Ваш комментарий будет добавлен после проверки модератором</p>
      <Row justify="center">
        <Button color="green"
                v-on:click.native="close">Хорошо
        </Button>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupCommentOnModeration',
  methods: {
    close() {
      this.$store.commit('CLOSE_POPUP');
    },
  },
};
</script>

<style lang="scss">
  @import "../../assets/style/utilits/index";

  .popup-comment-on-moderation {
    @include size(100%, 100);

    &__wrapper {
      @include size(100%, 100);
      padding: 40px;
      border-radius: 5px;
      background-color: $white;
    }

    &__title {
      margin: 0 0 24px;
    }
  }
</style>
